var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "about"
  }, [_c('advertisement-top-banner-text'), _c('section', {
    staticClass: "mt-n2",
    attrs: {
      "else": ""
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6 pb-2"
  }, [_c('b-img', {
    staticClass: "w-50 text-center",
    attrs: {
      "center": "",
      "src": "img/navbar/logo.png",
      "alt": "Image"
    }
  }), _vm._m(0), _c('li', [_vm._v(" “To imbibe happy and healthy living by serving Nutritious and Natural Food Produce.” ")]), _vm._m(1), _c('li', [_vm._v(" Passion : We believe in following our Passion to drive healthy regime in day to day living. ")]), _c('li', [_vm._v(" Togetherness : We believe in making a considerable change by combined efforts. ")]), _c('li', [_vm._v(" Challenges : We believe that Challenges gives impetus to higher Growth. ")]), _vm._m(2), _c('li', [_vm._v(" To serve every human with fresh, green and clean, residue free produce at an affordable rate. ")]), _c('li', [_vm._v(" Empower women and youth of the country & make them self sufficient. ")]), _c('li', [_vm._v(" Minimize plastic use in business to make World a better place to live in. ")])], 1), _vm._m(3)])])])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h3', {
    staticClass: "mt-n6"
  }, [_c('b', [_vm._v("Vision")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h3', {
    staticClass: "mt-2"
  }, [_c('b', [_vm._v("Values")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h3', {
    staticClass: "mt-2"
  }, [_c('b', [_vm._v("Mission")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-md-6 text-left pr-5 mt-5"
  }, [_c('h2', {
    staticClass: "text-center pb-2"
  }, [_vm._v("About eParisheva")]), _c('p', [_vm._v(" Over the last few centuries, we have redefined our relationship with Mother Nature from being a ‘nurturing mother’ to a ‘resource’. This fundamental shift in our relationship has had far-reaching consequences on every aspect of our lives. However, the one place where it has had a very profound impact is on the food we eat. The advent and proliferation of chemical-based agriculture in the last few decades has had the most adverse impact on two key stakeholders in our food production system: the small farmer and the consumer. Farmers are under great stress because chemical-based agriculture not only depletes the quality of land, water, and the adjoining ecosystem, it is also exploitative and financially unviable. On the other hand, consumers are paying a higher price for their food (owing to the number of middle-men in the food supply chain). We are also paying a very heavy price in terms of the adverse impact on our health and the environment, caused by harmful, chemical-laden food. ")]), _c('p', [_vm._v(" eParisheva is committed to providing nutritious products, cultivated in an ethical & socially responsible manner, within a sustainable ecosystem that benefits everybody involved. ")]), _c('p', [_vm._v(" Our values extend to the fields of flora & fauna: we adopt an ethical & social conscience in all our processes to minimize environmental impact & maximize sustainability. Our products are grown organically; cultivated and processed in a more ecologically sustainable way and naturally good for one’s health. Organic Farmers Co. strives to facilitate a healthier lifestyle for the urban individual by providing the well being & nutrients that the body needs. ")])]);
}]

export { render, staticRenderFns }