<template>
  <div class="about">
    <!-- about section start -->
    <advertisement-top-banner-text />
    <section class="mt-n2" else>
      <div class="container">
        <div class="row">
          <div class="col-md-6 pb-2">
            <b-img
              center
              src="img/navbar/logo.png"
              class="w-50 text-center"
              alt="Image"
            />
            <h3 class="mt-n6"><b>Vision</b></h3>
            <li>
              “To imbibe happy and healthy living by serving Nutritious and
              Natural Food Produce.”
            </li>
            <h3 class="mt-2"><b>Values</b></h3>
            <li>
              Passion : We believe in following our Passion to drive healthy
              regime in day to day living.
            </li>
            <li>
              Togetherness : We believe in making a considerable change by
              combined efforts.
            </li>
            <li>
              Challenges : We believe that Challenges gives impetus to higher
              Growth.
            </li>
            <h3 class="mt-2"><b>Mission</b></h3>
            <li>
              To serve every human with fresh, green and clean, residue free
              produce at an affordable rate.
            </li>
            <li>
              Empower women and youth of the country & make them self
              sufficient.
            </li>
            <li>
              Minimize plastic use in business to make World a better place to
              live in.
            </li>
          </div>
          <div class="col-md-6 text-left pr-5 mt-5">
            <h2 class="text-center pb-2">About eParisheva</h2>
            <p>
              Over the last few centuries, we have redefined our relationship
              with Mother Nature from being a ‘nurturing mother’ to a
              ‘resource’. This fundamental shift in our relationship has had
              far-reaching consequences on every aspect of our lives. However,
              the one place where it has had a very profound impact is on the
              food we eat. The advent and proliferation of chemical-based
              agriculture in the last few decades has had the most adverse
              impact on two key stakeholders in our food production system: the
              small farmer and the consumer. Farmers are under great stress
              because chemical-based agriculture not only depletes the quality
              of land, water, and the adjoining ecosystem, it is also
              exploitative and financially unviable. On the other hand,
              consumers are paying a higher price for their food (owing to the
              number of middle-men in the food supply chain). We are also paying
              a very heavy price in terms of the adverse impact on our health
              and the environment, caused by harmful, chemical-laden food.
            </p>
            <p>
              eParisheva is committed to providing nutritious products,
              cultivated in an ethical & socially responsible manner, within a
              sustainable ecosystem that benefits everybody involved.
            </p>
            <p>
              Our values extend to the fields of flora & fauna: we adopt an
              ethical & social conscience in all our processes to minimize
              environmental impact & maximize sustainability. Our products are
              grown organically; cultivated and processed in a more ecologically
              sustainable way and naturally good for one’s health. Organic
              Farmers Co. strives to facilitate a healthier lifestyle for the
              urban individual by providing the well being & nutrients that the
              body needs.
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isLoading: false,
    };
  },

  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
};
</script>
